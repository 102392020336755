import React, { Component } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
    padding: 20px;
    background-image: url('./BG_ING.jpg');
    background-size: cover;
    background-attachment: fixed; /* Ensure background image remains fixed */
    background-color: rgba(255, 255, 255, 0.9); /* 10% transparent white overlay */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    overflow-x: hidden; /* Hide horizontal overflow caused by background */

    @media (max-width: 768px) {
        padding: 10px; /* Reduced padding for mobile devices */
    }
`;

const FlowChartBox = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    position: relative;
    width: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child)::after {
        content: '';
        width: 2px;
        height: 20px;
        background: #ccc;
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
    }

    @media (max-width: 768px) {
        width: 100%; /* Full width on mobile devices */
        padding: 15px; /* Reduced padding for mobile devices */
    }
`;

const FlowChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const Separator = styled.div`
    width: 80%;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
`;

const Heading = styled.h2`
    color: #333;
    font-size: 1.5em;
    margin-bottom: 20px;
`;

const Text = styled.p`
    color: #666;
    font-size: 1em;
    line-height: 1.5;
`;

class Research extends Component {
    state = {
        researchAssistance: {
            title: 'Research Assistance and Project Development',
            description: 'Welcome to our Research Assistance and Project Development Department, where innovation meets excellence. Our dedicated team of researchers and experts is committed to helping scholars achieve their academic and professional goals. We offer a comprehensive range of services designed to support every stage of your research journey, from initial concept to final presentation.',
            services: [
                { title: 'Research Assistance', text: 'Our team provides unparalleled support in identifying research topics, conducting literature reviews, and formulating research methodologies. We ensure that your research is grounded in the latest academic advancements and aligned with your field’s best practices.' },
                { title: 'Project Development', text: 'Whether you’re working on a small-scale study or a large, complex project, we offer tailored project development services. Our experts assist in planning, executing, and managing your research projects, ensuring they are completed on time and within scope.' },
                { title: 'Thesis Writing Assistance', text: 'Writing a thesis can be daunting, but you don’t have to do it alone. Our experienced writers and editors help you craft a compelling thesis that meets all academic standards. From structuring your argument to refining your language, we provide comprehensive support to make your thesis stand out.' },
                { title: 'Research Paper Writing', text: 'Publishing research papers is crucial for academic and professional growth. Our team guides you through the entire process, from drafting your paper to choosing the right journal for publication. We ensure your research is presented clearly, concisely, and persuasively.' }
            ],
            howWeWork: [
                { title: 'Personalized Consultation', text: 'Every scholar\'s journey is unique. We begin with a personalized consultation to understand your specific needs, goals, and challenges. This allows us to tailor our services to provide the most effective support for your research.' },
                { title: 'Collaborative Approach', text: 'Our collaborative approach means you’ll work closely with our team throughout your research process. We believe in fostering a partnership where ideas are shared, and expertise is combined to produce high-quality research outcomes.' },
                { title: 'Expert Guidance', text: 'Our team comprises seasoned researchers, academics, and industry experts who bring a wealth of knowledge and experience. They provide invaluable guidance on research design, data analysis, and interpretation, helping you navigate complex issues and achieve robust results.' },
                { title: 'Ongoing Support', text: 'Research can be a long and iterative process. We offer ongoing support to ensure you stay on track and overcome any obstacles that arise. From initial brainstorming sessions to final revisions, our team is with you every step of the way.' }
            ],
            whyChooseUs: [
                { title: 'Commitment to Excellence', text: 'We are committed to delivering excellence in all our services. Our rigorous quality control ensures that every piece of work meets the highest academic standards.' },
                { title: 'Tailored Solutions', text: 'We understand that every research project is unique. Our tailored solutions are designed to meet the specific needs of each scholar, providing customized support that enhances your research experience.' },
                { title: 'Confidentiality and Integrity', text: 'We respect the confidentiality of your work and uphold the highest standards of academic integrity. You can trust us to handle your research with the utmost care and professionalism.' },
                { title: 'Success-Oriented', text: 'Our ultimate goal is to see you succeed. We measure our success by the achievements of our scholars and are dedicated to helping you reach your full potential.' }
            ],
            getStarted: 'Embark on your research journey with confidence, knowing that you have a team of dedicated professionals by your side. Contact us today to learn more about how we can assist you with your research, thesis writing, and project development needs. Together, we can turn your academic ambitions into reality.'
        }
    };

    renderSection = (sectionTitle, items) => (
        <FlowChartContainer>
            <Heading>{sectionTitle}</Heading>
            {items.map((item, index) => (
                <FlowChartBox key={index}>
                    <Heading>{index + 1}. {item.title}</Heading>
                    <Separator />
                    <Text>{item.text}</Text>
                </FlowChartBox>
            ))}
        </FlowChartContainer>
    );

    render() {
        const { researchAssistance } = this.state;

        return (
            <StyledSection className="about-3 section-padding">
                <FlowChartContainer>
                    <FlowChartBox>
                        <Heading>{researchAssistance.title}</Heading>
                        <Separator />
                        <Text>{researchAssistance.description}</Text>
                    </FlowChartBox>
                </FlowChartContainer>
                {this.renderSection('Our Services', researchAssistance.services)}
                {this.renderSection('How We Work with Scholars', researchAssistance.howWeWork)}
                {this.renderSection('Why Choose Us?', researchAssistance.whyChooseUs)}
                <FlowChartContainer>
                    <FlowChartBox>
                        <Heading>Get Started Today</Heading>
                        <Separator />
                        <Text>{researchAssistance.getStarted}</Text>
                    </FlowChartBox>
                </FlowChartContainer>
            </StyledSection>
        );
    }
}

export default Research;
