import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateContactForm = () => {
        let formErrors = {};
        if (!formData.firstName.trim()) {
            formErrors.firstName = "First name is required";
        }
        if (!formData.lastName.trim()) {
            formErrors.lastName = "Last name is required";
        }
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.phoneNumber) {
            formErrors.phoneNumber = "Phone number is required";
        } else if (!/^\+\d{1,3}\d{10}$/.test(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number must include country code and be 10 digits. Ex: +910123456789";
        }
        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateContactForm()) {
            return;
        }

        const googleFormUrl = 'https://docs.google.com/forms/u/3/d/e/1FAIpQLSfO5tz9thagy73ZztNSU8azScm6DmzQnWJQxeG_oDLPOAsy7w/formResponse';

        const formPayload = new FormData();
        formPayload.append('entry.1391290530', formData.firstName);
        formPayload.append('entry.1557027986', formData.lastName);
        formPayload.append('entry.833962111', formData.email);
        formPayload.append('entry.1400680955', formData.phoneNumber);
        formPayload.append('entry.652691104', formData.message);

        try {
            await fetch(googleFormUrl, {
                method: 'POST',
                body: formPayload,
                mode: 'no-cors',
            });
            setShowSnackbar(true);
            setTimeout(() => setShowSnackbar(false), 3000);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                message: '',
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting the form.');
        }
    };

    return (
        <section >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-10">
                        <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow">
                            <h2 className="text-center mb-4">Contact Us</h2>

                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            required
                                        />
                                        {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            required
                                        />
                                        {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    required
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                    required
                                />
                                {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                            </div>

                            <div className="form-group mb-4">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    rows="2"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                    required
                                ></textarea>
                                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary w-100"
                            >
                                Send Message
                            </button>

                            {showSnackbar && (
                                <div
                                    className="alert alert-success mt-3 text-center"
                                    role="alert"
                                >
                                    Form has been submitted. We will contact you shortly.
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
