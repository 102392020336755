import React from 'react';
import { PageBanner, EventsHappened } from '../layouts/eventsHappned/index';

import Layout from '../../common/Layout';

const CourseTwo = () => {
    return (
        <div className="page">
            <Layout>
                <PageBanner
                    title="Events Happened"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Events Happened"
                />
                <EventsHappened />
            </Layout>
        </div>
    );
}
export default CourseTwo;