import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InstructorData from '../../../data/instructor/InstructorData.json';
import './InstructorGrid.css'; // Import your CSS file

class InstructorGrid extends Component {
    componentDidMount() {
        this.setCardHeights();
        window.addEventListener('resize', this.setCardHeights);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCardHeights);
    }

    setCardHeights = () => {
        const teamItems = document.querySelectorAll('.team-item');
        let maxHeight = 0;

        teamItems.forEach(item => {
            const itemHeight = item.offsetHeight;
            if (itemHeight > maxHeight) {
                maxHeight = itemHeight;
            }
        });

        teamItems.forEach(item => {
            item.style.height = `${maxHeight}px`;
        });
    };

    render() {
        const TeamMembers = InstructorData.slice(0, 13);

        return (
            <section className="section-instructors section-padding">
                <div className="container">
                    <div className="row">
                        {TeamMembers.map((data) => (
                            <div className="col-lg-3 col-md-6 col-sm-6" key={data.id}>
                                <div className="team-item mb-5">
                                    <div className="team-img">
                                        <img src={`/assets/images/team/${data.imageLg}`} alt="" className="img-fluid" />
                                        <ul className="team-socials list-inline">
                                            <li className="list-inline-item"><Link to={data.linkedInUrl}><i className="fab fa-linkedin-in"></i></Link></li>
                                        </ul>
                                    </div>
                                    <div className="team-content">
                                        <div className="team-info">
                                            <h4>{data.name}</h4>
                                            <p>{data.designation}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default InstructorGrid;
