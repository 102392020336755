import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

class Internship extends Component {
    constructor(props) {
        super(props);
        this.state = {
            internshipItems: [
                {
                    id: 1,
                    image: '/assets/images/course/fullstack.png',
                    name: 'Full Stack Development Internship',
                    description: 'Gain hands-on experience in full stack web development with our intensive internship program. Work on real-world projects and learn from industry experts.',
                    duration: '6 months'
                },
                {
                    id: 2,
                    image: '/assets/images/course/datascience.jpg',
                    name: 'Data Science Internship',
                    description: 'Dive into data science and analytics with our comprehensive internship. Learn to analyze, interpret, and visualize data to drive informed decision-making.',
                    duration: '4 months'
                },
                {
                    id: 3,
                    image: '/assets/images/course/dataanalytics.jpg',
                    name: 'Data Analytics Internship',
                    description: 'Get hands-on experience in data analytics. Learn to use data to uncover insights and support strategic decisions.',
                    duration: '3 months'
                },
                {
                    id: 4,
                    image: '/assets/images/course/cybersecurity.jpg',
                    name: 'Cyber Security Internship',
                    description: 'Join our cybersecurity internship to learn about protecting data and systems from cyber threats. Gain practical skills in security measures and best practices.',
                    duration: '5 months'
                },
                {
                    id: 5,
                    image: '/assets/images/course/bootcamp.jpg',
                    name: 'Code Start Bootcamp Internship',
                    description: 'Jumpstart your coding career with our intensive bootcamp internship. Learn coding fundamentals and work on real-world projects.',
                    duration: '8 weeks'
                },
                {
                    id: 6,
                    image: '/assets/images/course/matlab.png',
                    name: 'MATLAB Internship',
                    description: 'Explore the capabilities of MATLAB in our focused internship program. Learn to solve complex engineering and scientific problems using MATLAB.',
                    duration: '3 months'
                },
                {
                    id: 7,
                    image: '/assets/images/course/bigdata.jpg',
                    name: 'Big Data Internship',
                    description: 'Harness the power of big data in our internship program. Learn to manage and analyze large datasets to gain strategic insights.',
                    duration: '6 months'
                },
                {
                    id: 8,
                    image: '/assets/images/course/powerbi.svg',
                    name: 'Microsoft PowerBI Internship',
                    description: 'Master Power BI in our internship program. Learn to create dynamic data visualizations and drive business intelligence solutions.',
                    duration: '4 months'
                }
            ],
            modalShow: false,
            selectedInternship: null,
            registerModalShow: false,
            loading: false,
            form: {
                name: '',
                contactNumber: '',
                email: '',
                qualification: '',
                message: '',
                internship: ''
            }
        };
    }

    handleShowModal = (internship) => {
        this.setState({
            modalShow: true,
            selectedInternship: internship,
            form: { ...this.state.form, internship: internship.name }
        });
    }

    handleHideModal = () => {
        this.setState({
            modalShow: false,
            selectedInternship: null
        });
    }

    handleShowRegisterModal = () => {
        this.setState({
            registerModalShow: true,
            modalShow: false
        });
    }

    handleHideRegisterModal = () => {
        this.setState({
            registerModalShow: false
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            form: { ...prevState.form, [name]: value }
        }));
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const response = await axios.post('/.netlify/functions/internshipregister', this.state.form);
            if (response.status === 201) {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        registerModalShow: false
                    });
                    alert('Registration successful! You can now download the internship details.');
                }, 1000);
            }
        } catch (err) {
            console.error('Error submitting form:', err);
            this.setState({ loading: false });
        }
    }

    render() {
        const { modalShow, selectedInternship, registerModalShow, loading, form } = this.state;

        return (
            <section className="about-3 section-padding">
                <div className="container">
                    <div className="row">
                        {this.state.internshipItems.map(internship => (
                            <div className="col-lg-4 col-md-6 mb-4" key={internship.id}>
                                <div className="card h-100">
                                    <img src={internship.image} className="card-img-top" alt={internship.name} style={{ width: '340px', height: '223px' }} />
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">{internship.name}</h5>
                                        <p className="card-text flex-grow-1">{internship.description}</p>
                                        <p className="card-text"><strong>Duration:</strong> {internship.duration}</p>
                                        <Button variant="primary" onClick={() => this.handleShowModal(internship)}>
                                            Learn More
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {selectedInternship && (
                    <Modal
                        show={modalShow}
                        onHide={this.handleHideModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {selectedInternship.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{selectedInternship.description}</p>
                            <p><strong>Duration:</strong> {selectedInternship.duration}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideModal}>Close</Button>
                            <Button variant="primary" onClick={this.handleShowRegisterModal}>Register</Button>
                        </Modal.Footer>
                    </Modal>
                )}

                <Modal
                    show={registerModalShow}
                    onHide={this.handleHideRegisterModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Register for {form.internship}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formContactNumber">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contactNumber"
                                    value={form.contactNumber}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={form.email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formQualification">
                                <Form.Label>Qualification</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="qualification"
                                    value={form.qualification}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    value={form.message}
                                    onChange={this.handleChange}
                                    rows={3}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? 'Registering...' : 'Register'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}

export default Internship;
