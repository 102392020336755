import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CourseCat01 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: [
                {
                    id: 1,
                    title: 'Full Stack',
                    img: 'assets/images/icon/icon1.png',
                },
                {
                    id: 2,
                    title: 'Data Science',
                    img: 'assets/images/icon/icon2.png',
                },
                {
                    id: 3,
                    title: 'Data Analytics',
                    img: 'assets/images/icon/icon7.png',
                },
                {
                    id: 4,
                    title: 'Cyber Security',
                    img: 'assets/images/icon/icon4.png',
                },
                {
                    id: 5,
                    title: 'CodeStart Bootcamp',
                    img: 'assets/images/icon/icon6.png',
                },
                {
                    id: 6,
                    title: 'MATLAB',
                    img: 'assets/images/icon/icon2.png',
                },
                {
                    id: 7,
                    title: 'Big Data',
                    img: 'assets/images/icon/icon9.png',
                },
                {
                    id: 8,
                    title: 'Microsoft PowerBI',
                    img: 'assets/images/icon/icon8.png',
                },
                {
                    id: 9,
                    title: 'React JS',
                    img: 'assets/images/icon/icon10.svg',
                },
                {
                    id: 10,
                    title: 'Node JS',
                    img: 'assets/images/icon/icon11.svg',
                },
                {
                    id: 11,
                    title: 'UI / UX',
                    img: 'assets/images/icon/icon12.png',
                },
                {
                    id: 12,
                    title: 'Microsoft Excel',
                    img: 'assets/images/icon/icon13.svg',
                },
            ]
        }
    }

    render() {
        // Course category

        return (
            <section className="section-padding">
                <div className="container-fluid container-grid">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="heading mb-50 text-center">
                                <h2>Available Categories</h2>
                                <span className="subheading">Stay ahead with our market-leading courses</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            this.state.category.map((data) => (
                                <div className="col-xl-3 col-lg-4 col-md-6" key={data.id}>
                                    <div className="single-course-category style-2">
                                        <div className="course-cat-icon">
                                            <img src={data.img} alt={data.title} className="img-fluid" />
                                        </div>
                                        <div className="course-cat-content">
                                            <h4 className="course-cat-title">
                                                <Link to="#">{data.title}</Link>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default CourseCat01;
