import React from 'react';
import { PageBanner, CourseGrid } from '../layouts/course02/index';

import Layout from '../../common/Layout';

const CourseTwo = () => {
    return (
        <div className="page">
            <Layout>
                <PageBanner
                    title="Our Courses"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Our Courses"
                />
                <CourseGrid />
            </Layout>
        </div>
    );
}
export default CourseTwo;