import React, { Component } from 'react';

import bannerImg from './bannerimg.png';

class Banner extends Component {
    render() {
        return (
            <section className="banner banner-style-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-xl-6 col-lg-6">
                            <div className="banner-content">
                                <h1>Learn Upskill Succeed</h1>
                                <p>Elevate your career with our industry relevant in-person upskilling Bootcamp</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-xl-6 col-lg-6">
                            <div className="banner-img-round mt-4 mt-lg-0">
                                <img width="85% " src={bannerImg} alt="Banner" className="img-fluid" /> {/* Use the imported image */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Banner;
