import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const HeaderTwo = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const openMenu = (e) => {
        e.preventDefault();
        setIsMenuOpen(true);
    };

    const closeMenu = (e) => {
        e.preventDefault();
        setIsMenuOpen(false);
    };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <header className="header-style-1">
            <div className="header-topbar topbar-style-1">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-xl-8 col-lg-8 col-sm-6">
                            <div className="header-contact text-center text-sm-start text-lg-start">
                                <Link to="/" className="text-lg font-semibold">InfiVidhya Pvt. Ltd.</Link>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="header-socials text-center text-lg-end">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <Link to="https://www.facebook.com/infividhya" aria-label="Facebook">
                                            <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.instagram.com/infividhya" aria-label="Instagram">
                                            <i className="fab fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.linkedin.com/company/infividhya/" aria-label="LinkedIn">
                                            <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Navbar */}
            <div className={`header-navbar navbar-sticky ${windowWidth <= 991 ? 'mobile-menu' : ''}`}>
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        {/* Logo */}
                        <div className="site-logo py-2 px-4">
                            <Link to="#">
                                <img src="/assets/images/logo.png" alt="InfiVidhya Logo" className="img-fluid" />
                            </Link>
                        </div>

                        {/* Mobile Menu Toggle */}
                        <div className="offcanvas-icon d-block d-lg-none">
                            <button onClick={openMenu} className="nav-toggler flex items-center justify-center" aria-label="Open menu">
                                <i className="fal fa-bars text-2xl"></i>
                            </button>
                        </div>

                        {/* Navigation */}
                        <nav className={`site-navbar ms-auto ${isMenuOpen ? 'menu-on' : ''}`}>

                            {isMenuOpen && (
                                <button onClick={closeMenu} className="nav-close flex items-center justify-center w-10 h-10" aria-label="Close menu">
                                    <i className="fal fa-times text-2xl"></i>
                                </button>
                            )}
                            <Nav />
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderTwo;