import React, { Component } from 'react';
import './Features.css';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [
                {
                    id: 1,
                    icon: 'fa fa-graduation-cap',
                    title: 'Education',
                    text: 'Providing foundational knowledge and skills for lifelong learning.',
                    extraClassName: 'icon-bg-1',
                },
                {
                    id: 2,
                    icon: 'far fa-book',
                    title: 'Training',
                    text: 'Equipping individuals with specialized competencies for professional growth.',
                    extraClassName: 'icon-bg-3',
                },
                {
                    id: 3,
                    icon: 'far fa-flask',
                    title: 'Research',
                    text: 'Exploring new frontiers and expanding the boundaries of knowledge.',
                    extraClassName: 'icon-bg-3',
                },
                {
                    id: 4,
                    icon: 'fa fa-cogs',
                    title: 'Development',
                    text: 'Creating innovative solutions and advancing progress through practical implementation.',
                    extraClassName: 'icon-bg-1',
                },
            ]
        }
    }

    render() {
        return (
            <section className="features-intro">
                <div className="container">
                    <div className="feature-inner">
                        <div className="row feature-row">
                            {
                                this.state.features.map((data) => (
                                    <div className="col-xl-6 col-lg-6 feature-col" key={data.id}>
                                        <div className="feature-item feature-style-left">
                                            <div className={`feature-icon ${data.extraClassName}`}>
                                                <i className={data.icon}></i>
                                            </div>
                                            <div className="feature-text">
                                                <h4>{data.title}</h4>
                                                <p>{data.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;
