import React, { Component } from 'react';
import './EventsHappened.css'; // Ensure to import the CSS file

class EventsHappened extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [
                {
                    "id": 1,
                    "img": "/assets/images/events/1.jpeg",
                    "event": "Founding Batch of the Data Science Course",
                    "institute": "InfiVidhya",
                    "date": "Mon, 14 Aug 2023",
                    "description": "🎓 Our Founding Batch of the Data Science Course is set to embark on a remarkable 6-month journey. Through our industry-oriented in-person curriculum and personalized mentoring sessions led by industry experts and trained academicians, we are committed to nurturing these students into proficient data scientists ready to conquer the world of IT."
                },
                {
                    "id": 2,
                    "img": "/assets/images/events/2.jpg",
                    "event": "Interactive Session with Abhishek Paul",
                    "institute": "InfiVidhya",
                    "date": "Tue, 15 Aug 2023",
                    "description": "A momentous shout-out to Abhishek Paul, Management Consultant at Accenture Strategy, who graced the inauguration of our Founding Batch of Data Science at Infividhya! 🌟"
                },
                {
                    "id": 3,
                    "img": "/assets/images/events/3.jpeg",
                    "event": "Interactive Session with Keivaly Pujara",
                    "institute": "InfiVidhya",
                    "date": "Thu, 17 Aug 2023",
                    "description": "What a privilege it was to welcome Keivaly Pujara, Data Scientist at MathCo, to our Learning Center at Infividhya!"
                },
                {
                    "id": 4,
                    "img": "/assets/images/events/4.jpeg",
                    "event": "Sessions on Computer Vision",
                    "institute": "Adani Institute of Digital Technology Management",
                    "date": "Mon, 27 Nov 2023",
                    "description": "Infividhya is thrilled to announce a collaboration with the Adani Institute of Digital Technology Management for exclusive sessions on Computer Vision! Stay tuned for insightful discussions and cutting-edge insights in the world of AI and Computer Vision!"
                },
                {
                    "id": 5,
                    "img": "/assets/images/events/5.jpeg",
                    "event": "Java in Hadoop",
                    "institute": "Narayana Business School",
                    "date": "Sat, 03 Feb 2024",
                    "description": "In our collaboration with Narayana Business School at InfiVidhya, we had a rewarding experience. The latest session, expertly conducted by Ashish Singh, focused on Java, thoughtfully crafted to familiarize them with the complexities of Java in Hadoop. We are pleased with the students' enthusiasm and remain dedicated to nurturing ongoing learning and exploration through these customized sessions."
                },
                {
                    "id": 6,
                    "img": "/assets/images/events/6.jpeg",
                    "event": "Interactive Session on MATLAB",
                    "institute": "Narayana Business School",
                    "date": "Fri, 02 Feb 2024",
                    "description": "At InfiVidhya, we had an enriching experience collaborating with students of Narayana Business School specializing in Quantitative Finance. The recent session focused on MATLAB, meticulously designed to acquaint them with the intricacies of the Financial module within MATLAB. This interactive engagement aimed to provide students with comprehensive insights into leveraging MATLAB's capabilities for financial analysis and modeling. We are delighted by the enthusiasm displayed by the students and are committed to fostering continued learning and exploration in quantitative finance through such tailored sessions."
                },
                {
                    "id": 7,
                    "img": "/assets/images/events/7.jpeg",
                    "event": "Workshop on Introduction to Computer Vision: Fundamentals and Applications",
                    "institute": "Indus University",
                    "date": "Tue, 27 Feb 2024",
                    "description": "InfiVidhya has successfully concluded a dynamic and insightful one-day workshop on 'Introduction to Computer Vision: Fundamentals and Applications' for the Computer Engineering students of Indus University Ahmedabad."
                },
                {
                    "id": 8,
                    "img": "/assets/images/events/8.jpeg",
                    "event": "One-Day Workshop on Navigating Data Science and Data Analytics",
                    "institute": "GEC Gandhinagar",
                    "date": "Mon, 18 Mar 2024",
                    "description": "We are delighted to announce the successful conclusion of our dynamic one-day workshop on Navigating Data Science and Data Analytics, hosted by InfiVidhya at Government Engineering College, Gandhinagar. It was an enriching session packed with valuable insights and practical knowledge, tailored specifically for the participants."
                },
                {
                    "id": 9,
                    "img": "/assets/images/events/9.jpeg",
                    "event": "Corporate Training Program",
                    "institute": "Eccentric Clinical Research Private Limited",
                    "date": "Thu, 25 Jan 2024",
                    "description": "Successfully completed corporate training program at Eccentric Clinical Research Private Limited, unlocking new potentials and skills for professional excellence. We are super excited to announce the successful completion of corporate training program at Eccentric Clinical Research Private Limited, a Clinical Research Firm. This is a huge milestone for us, as it opens up new possibilities for innovation in Clinical Data Management (CDM)."
                },
                {
                    "id": 10,
                    "img": "/assets/images/events/10.jpeg",
                    "event": "One-Day PowerBi Workshop",
                    "institute": "Monark University",
                    "date": "Thu, 07 Mar 2024",
                    "description": "InfiVidhya successfully completed a vibrant one-day PowerBi workshop at Monark University, Ahmedabad. It was an engaging session filled with insights and practical knowledge, tailored specifically for the Management students at Monark University."
                },
                {
                    "id": 11,
                    "img": "/assets/images/events/11.jpeg",
                    "event": "Women's Day Celebration",
                    "institute": "InfiVidhya",
                    "date": "Thu, 14 Mar 2024",
                    "description": "Glimpse of Women's Day celebration at InfiVidhya."
                },
                {
                    "id": 12,
                    "img": "/assets/images/events/12.jpeg",
                    "event": "One-Day Workshop on Computer Vision using OpenCV",
                    "institute": "Shree Swaminarayan Institute of Technology",
                    "date": "Wed, 20 Mar 2024",
                    "description": "We are elated to share the incredible success of our recent one-day workshop on Computer Vision using OpenCV held at Shree Swaminarayan Institute of Technology! The event was a phenomenal exploration into the realm of cutting-edge technology, meticulously crafted by InfiVidhya to provide participants with invaluable insights and practical skills."
                },
                {
                    "id": 13,
                    "img": "/assets/images/events/13.png",
                    "event": "Project Interaction with Client",
                    "institute": "InfiVidhya",
                    "date": "Thu, 28 Mar 2024",
                    "description": "Our interns are diving into real-world experiences like never before! At InfiVidhya, we believe in hands-on learning, and that's why we are excited to announce that our interns are now getting the chance to interact directly with our esteemed clients!"
                },
                {
                    "id": 14,
                    "img": "/assets/images/events/14.png",
                    "event": "One-Day Training on Matplotlib Library",
                    "institute": "Narayana Business School",
                    "date": "Sat, 13 Jan 2024",
                    "description": "Session on Matplotlib which is a powerful plotting library in Python used for creating static, animated, and interactive visualizations."
                },
                {
                    "id": 15,
                    "img": "/assets/images/events/15.jpg",
                    "event": "MoU Signing with NBS",
                    "institute": "Narayana Business School",
                    "date": "Sun, 07 Jan 2024",
                    "description": "We are super excited to share the news of a Memorandum of Understanding (MoU) between InfiVidhya and Narayana Business School. This is a huge milestone for us, as it opens up new possibilities for collaboration and innovation in education and business leadership."
                },
                {
                    "id": 16,
                    "img": "/assets/images/events/16.jpg",
                    "event": "Placement Cell MoU with AIT",
                    "institute": "AIT",
                    "date": "Thu, 21 Dec 2023",
                    "description": "Thrilled to share that InfiVidhya has inked a strategic partnership with the Placement Cell of Ahmedabad Institute of Technology! This MoU solidifies our joint commitment to empowering students through comprehensive training and providing enhanced placement avenues. Exciting times ahead for skill development."
                },
                {
                    "id": 17,
                    "img": "/assets/images/events/17.jpg",
                    "event": "Data Analytics Workshop",
                    "institute": "Indus University",
                    "date": "Sun, 27 Aug 2023",
                    "description": "The enthusiasm and dedication exhibited by our participants were truly inspiring. Their eagerness to learn, experiment, and innovate reinforced my belief in the power of continuous education and knowledge sharing."
                },
                {
                    "id": 18,
                    "img": "/assets/images/events/18.jpg",
                    "event": "HR Mentoring with Mr. Shobhit Tyagi",
                    "institute": "Shashwat HR",
                    "date": "Thu, 14 Sep 2023",
                    "description": "I wanted to take a moment to express my heartfelt thanks to Mr. Shobhit Tyagi, Founder and CEO - Shashwat HR, for their invaluable contribution in guiding and mentoring our students toward brighter career prospects."
                },
                {
                    "id": 19,
                    "img": "/assets/images/events/19.png",
                    "event": "Insights of Data Analytics in Business Management",
                    "institute": "SOM Lalit Institute of Management Studies",
                    "date": "Thu, 11 Apr 2024",
                    "description": "InfiVidhya is excited to announce the successful completion of our Expert Talk on 'Insights of Data Analytics in Business Management' at SOM Lalit Institute of Management Studies."
                },
                {
                    "id": 20,
                    "img": "/assets/images/events/20.png",
                    "event": "Opportunities for Management Students in the Field of Data Analysis",
                    "institute": "SOM Lalit Institute of Management Studies",
                    "date": "Mon, 22 Apr 2024",
                    "description": "InfiVidhya is excited to announce the successful completion of our Expert Talk on 'Opportunities for Management Students in the Field of Data Analysis' at SOM Lalit Institute of Management Studies!"
                },
                {
                    "id": 21,
                    "img": "/assets/images/events/21.jpg",
                    "event": "Navigating the Landscape of Data Science and Big Data Analytics",
                    "institute": "Monark University",
                    "date": "Tue, 13 Feb 2024",
                    "description": "InfiVidhya has just concluded a dynamic and insightful 2-day workshop on 'Navigating the Landscape of Data Science and Big Data Analytics' for the BCA and MCA students of Monark University!"
                },
                {
                    "id": 22,
                    "img": "/assets/images/events/22.jpg",
                    "event": "Workshop on Data Analytics Bootcamp",
                    "institute": "Indus University",
                    "date": "Mon, 17 Jun 2024",
                    "description": "We are thrilled to share that our team, including Dr. Sejal Bhavsar, Dr. Archana Singh, and Mr. Urmay Shah, recently conducted a hands-on session on Python for Data Science as part of the Data Science Certification Program at Indus University's Computer Engineering Department."
                }
            ],
            expandedEventId: null,
        };
    }

    // Function to toggle the expanded state of an event
    toggleEventDetails = (eventId) => {
        this.setState((prevState) => ({
            expandedEventId: prevState.expandedEventId === eventId ? null : eventId,
        }));
    }

    render() {
        const { events, expandedEventId } = this.state;

        return (
            <div className="events-container">
                <h2>Events Happened</h2>
                <div className="events-grid">
                    {events.map(event => (
                        <div key={event.id} className="event-card" onClick={() => this.toggleEventDetails(event.id)}>
                            <img src={event.img} alt={event.event} className="event-image" />
                            <div className="event-content">
                                <h3>{event.event}</h3>
                                {expandedEventId === event.id && (
                                    <div className="event-details">
                                        <p><strong>Institute:</strong> {event.institute}</p>
                                        <p><strong>Date:</strong> {event.date}</p>
                                        <p>{event.description}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default EventsHappened;
