import React from 'react';
import { PageBanner, Internship } from '../layouts/internship';

import Layout from '../../common/Layout';

const Internshippage = () => {
    return (
        <div className="page">
            <Layout>
                <PageBanner
                    title="Our Courses"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Internships Offered"
                />
                <Internship />
            </Layout>
        </div>
    );
};

export default Internshippage;
