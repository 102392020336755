// AdminFormEntries.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminFormEntries = () => {
  const [formEntries, setFormEntries] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/.netlify/functions/fetchFormData?sortBy=timestamp:desc');
      setFormEntries(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="table-container">
      <h2>Form Entries</h2>
      <table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Message</th>
            <th>Timestamp</th> {/* New column for timestamp */}
          </tr>
        </thead>
        <tbody>
          {formEntries.map((entry, index) => (
            <tr key={index}>
              <td>{entry.fullname}</td>
              <td>{entry.email}</td>
              <td>{entry.subject}</td>
              <td>{entry.message}</td>
              <td>{new Date(entry.timestamp).toLocaleString()}</td> {/* Display timestamp */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminFormEntries;
