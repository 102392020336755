import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

class CourseGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseItems: [
                {
                    id: 1,
                    image: '/assets/images/course/fullstack.png',
                    name: 'Full Stack',
                    description: 'Master the art of web development with our comprehensive bootcamp. From HTML to advanced JavaScript frameworks, we cover everything you need to know to build dynamic and responsive websites.'
                },
                {
                    id: 2,
                    image: '/assets/images/course/datascience.jpg',
                    name: 'Data Science',
                    description: 'Dive into the world of data science and analytics with our introductory course. Learn essential concepts, tools, and techniques to analyze and interpret data for informed decision-making.'
                },
                {
                    id: 3,
                    image: 'assets/images/course/dataanalytics.jpg',
                    name: 'Data Analytics',
                    description: 'Build powerful and intuitive mobile applications for iOS and Android platforms. Learn app design, development, and deployment strategies to create engaging mobile experiences.'
                },
                {
                    id: 4,
                    image: 'assets/images/course/cybersecurity.jpg',
                    name: 'Cyber Security',
                    description: 'Protect data, systems, and networks from cyber threats with our cybersecurity essentials course. Learn about common attack vectors, security measures, and best practices to safeguard digital assets.'
                },
                {
                    id: 5,
                    image: 'assets/images/course/bootcamp.jpg',
                    name: 'Code Start Bootcamp',
                    description: 'Explore the fundamentals of cloud computing and infrastructure. Understand cloud service models, deployment models, and key providers in this rapidly evolving field.'
                },
                {
                    id: 6,
                    image: 'assets/images/course/MATLAB.png',
                    name: 'MATLAB',
                    description: 'Unlock the potential of machine learning with our foundational course. Discover algorithms, models, and techniques to train intelligent systems for various real-world applications.'
                },
                {
                    id: 7,
                    image: 'assets/images/course/bigdata.jpg',
                    name: 'Big Data',
                    description: 'Harness the power of vast data: Master Big Data for groundbreaking insights and strategic innovation.'
                },
                {
                    id: 8,
                    image: 'assets/images/course/powerbi.svg',
                    name: 'Microsoft PowerBI',
                    description: 'Visualize your success: Master Power BI for dynamic data storytelling and informed business strategies.'
                },
                {
                    id: 9,
                    image: 'assets/images/course/react.png',
                    name: 'React JS',
                    description: 'Craft interactive experiences: Master React JS for cutting-edge web applications and seamless user interfaces.'
                },
                {
                    id: 10,
                    image: 'assets/images/course/node.png',
                    name: 'Node JS',
                    description: 'Empower your backend: Master Node JS for efficient server-side development and scalable applications.'
                },
                {
                    id: 11,
                    image: 'assets/images/course/uiux.png',
                    name: 'UI / UX',
                    description: 'Design with purpose: Master UI/UX for intuitive, user-centered designs that drive engagement.'
                },
                {
                    id: 12,
                    image: 'assets/images/course/excel.png',
                    name: 'Microsoft Excel',
                    description: 'Unlock data potential: Master MS Excel for advanced data analysis and insightful business decision-making.'
                },
            ],
            modalShow: false,
            selectedCourse: null,
            registerModalShow: false,
            downloadModalShow: false,
            loading: false,
            form: {
                name: '',
                contactNumber: '',
                email: '',
                qualification: '',
                message: '',
                course: ''
            },
            confirmationMessage: ''
        };
    }

    handleShowModal = (course) => {
        this.setState({
            modalShow: true,
            selectedCourse: course,
            form: { ...this.state.form, course: course.name }
        });
    }

    handleHideModal = () => {
        this.setState({
            modalShow: false,
            selectedCourse: null
        });
    }

    handleShowRegisterModal = () => {
        this.setState({
            registerModalShow: true,
            modalShow: false
        });
    }

    handleHideRegisterModal = () => {
        this.setState({
            registerModalShow: false
        });
    }

    handleShowDownloadModal = () => {
        this.setState({
            downloadModalShow: true,
            registerModalShow: false
        });
    }

    handleHideDownloadModal = () => {
        this.setState({
            downloadModalShow: false
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            form: { ...prevState.form, [name]: value }
        }));
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const response = await axios.post('/.netlify/functions/register', this.state.form);
            if (response.status === 201) {
                setTimeout(() => {
                    this.setState((prevState) => ({
                        loading: false,
                        confirmationMessage: 'Registration successful! You can now download the course materials.',
                        downloadModalShow: true,
                        registerModalShow: false
                    }));
                }, 1000);
            }
        } catch (err) {
            console.error('Error submitting form:', err);
            this.setState({ loading: false });
        }
    }

    render() {
        const { modalShow, selectedCourse, registerModalShow, downloadModalShow, loading, form, confirmationMessage } = this.state;

        const getDownloadUrl = () => {
            return `/assets/pdf/${selectedCourse?.name.replace(/\s+/g, '_')}.pdf`;
        };

        return (
            <section className="about-3 section-padding">
                <div className="container">
                    <div className="row">
                        {this.state.courseItems.map(course => (
                            <div className="col-lg-4 col-md-6 mb-4" key={course.id}>
                                <div className="card h-100">
                                    <img src={course.image} className="card-img-top" alt={course.name} style={{ width: '340px', height: '223px' }} />
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">{course.name}</h5>
                                        <p className="card-text flex-grow-1">{course.description}</p>
                                        <Button variant="primary" onClick={() => this.handleShowModal(course)}>
                                            Learn More
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {selectedCourse && (
                    <Modal
                        show={modalShow}
                        onHide={this.handleHideModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {selectedCourse.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{selectedCourse.description}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideModal}>Close</Button>
                            <Button variant="primary" onClick={this.handleShowRegisterModal}>Register</Button>
                        </Modal.Footer>
                    </Modal>
                )}

                <Modal
                    show={registerModalShow}
                    onHide={this.handleHideRegisterModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Register for {form.course}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formContactNumber">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contactNumber"
                                    value={form.contactNumber}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={form.email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formQualification">
                                <Form.Label>Basic Qualification</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="qualification"
                                    value={form.qualification}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={form.message}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={downloadModalShow}
                    onHide={this.handleHideDownloadModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Download Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{confirmationMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleHideDownloadModal}>Close</Button>
                        <Button variant="primary" href={getDownloadUrl()} target="_blank">
                            Download details for {selectedCourse?.name}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        );
    }
}

export default CourseGrid;
