import React from 'react';
import HeaderTwo from './header/Header';
import FooterTwo from './footer/Footer';

const Layout = ({ children }) => {
    return (
        <>
            <main className="main-wrapper">
                <HeaderTwo />
                {children}
                <FooterTwo />
            </main>
        </>
    )
}
export default Layout;