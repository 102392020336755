import React, { Component } from 'react';


class AboutComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [
                {
                    id: 1,
                    icon: 'fa fa-rocket',
                    title: 'Vision',
                    extraClassName: 'icon-bg-3',
                    text: "At InfiVidhya, we aim to revolutionize education by bridging the gap between traditional degrees and the dynamic IT industry. Our vision is to empower individuals with the knowledge and skills needed to excel in this ever-changing field. By providing a comprehensive learning platform, we enable job aspirants, students, and professionals to make a significant impact in the world of Information Technology."
                },
                {
                    id: 2,
                    icon: 'far fa-flag-checkered',
                    title: 'Mission',
                    extraClassName: 'icon-bg-2',
                    text: "Our mission is to offer practical and industry-relevant courses that provide a holistic learning experience. We empower learners with the skills, confidence, and adaptability needed to excel in their IT domains. Through collaborations with experts, we deliver top-quality education that enables individuals to upskill, transition careers, and stay ahead in the evolving IT landscape."
                },


            ]
        }
    }
    render() {
        return (
            <section className="about-3">
                <div className="container">
                    <div className="row align-items-center justify-content-between">

                        <div className="col-xl-6 col-lg-6">
                            <div className="about-content mt-5 mt-lg-0">
                                <div className="heading mb-50">
                                    <span className="subheading">Learn, Upskill, Succeed</span>
                                    <h2 className="font-lg">Our Values</h2>
                                </div>

                                <div className="about-features">
                                    {
                                        this.state.features.map((data, i) => (

                                            <div className="feature-item feature-style-left" key={data.id}>
                                                <div className={`feature-icon icon-radius ${data.extraClassName}`}>
                                                    <i className={data.icon}></i>
                                                </div>
                                                <div className="feature-text">
                                                    <h4>{data.title}</h4>
                                                    <p>{data.text}</p>
                                                </div>
                                            </div>
                                        ))

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="about-img">
                                <img src="assets/images/banner/img_8.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        );
    }
}

export default AboutComp;
