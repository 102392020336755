import React from 'react';
import { PageBanner, AboutComp, Features } from '../layouts/about/index';
import Layout from '../../common/Layout';


const AboutMain = () => {
    return (
        <div className="about">
            <Layout>
                <PageBanner
                    title="About us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About"
                />
                <Features />
                <AboutComp />

            </Layout>
        </div>
    );
}

export default AboutMain;