import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [
                {
                    id: 1,
                    icon: 'fa fa-book',
                    title: 'Innovative Learning Platform',
                    extraClassName: 'icon-bg-3',
                    text: "InfiVidhya offers a cutting-edge educational platform that transcends traditional boundaries. Dive into the world of Information Technology with curated bootcamps led by industry experts. Whether you're a seasoned pro or a curious beginner, we provide an immersive experience to propel you to IT excellence."
                },
                {
                    id: 2,
                    icon: 'far fa-file-certificate',
                    title: 'Knowledge Expansion',
                    extraClassName: 'icon-bg-2',
                    text: "Join a transformative journey alongside thought leaders and passionate learners. Stay ahead with the latest advancements in IT through our carefully curated courses. From upskilling professionals to igniting curiosity in beginners, our platform fosters growth and exploration."
                },
                {
                    id: 3,
                    icon: 'fad fa-users',
                    title: 'Community Engagement',
                    extraClassName: 'icon-bg-1',
                    text: "Connect with like-minded individuals in an inclusive learning environment. Exchange ideas, collaborate, and network with peers and experts. At InfiVidhya, learning isn't just solitary; it's a collaborative venture towards collective success."
                },

            ]
        }
    }
    render() {
        return (

            <section className="about-3 section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-5 col-lg-6">
                            <div className="about-img">
                                <img src="assets/images/banner/img_9.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-content mt-5 mt-lg-0">
                                <div className="heading mb-50">
                                    <span className="subheading">Learn, Upskill, Succeed</span>
                                    <h2 className="font-lg">Who are we ?</h2>
                                </div>

                                <div className="about-features">
                                    {
                                        this.state.features.map((data, i) => (

                                            <div className="feature-item feature-style-left" key={data.id}>
                                                <div className={`feature-icon icon-radius ${data.extraClassName}`}>
                                                    <i className={data.icon}></i>
                                                </div>
                                                <div className="feature-text">
                                                    <h4>{data.title}</h4>
                                                    <p>{data.text}</p>
                                                </div>
                                            </div>
                                        ))

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        );
    }
}

export default About;
