import React, { Component } from 'react';
import Slider from "react-slick";
import { HomeTwoTestimonial } from '../../../utils/script';

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    img: '/assets/images/clients/hardik.png',
                    text: "Infividhya is a true gem! Their unwavering support and dedication to providing top-quality learning experiences have made my journey here exceptional. Every step felt purposeful and rewarding. I thank them enough for playing such a valuable part in my educational journey.",
                    name: 'Hardik Koladiya',
                    desigantion: 'Bluwheelz',
                },
                {
                    id: 2,
                    img: 'assets/images/clients/dhruvil.png',
                    text: 'My journey with Infividhya has been transformative. Thanks to their exceptional support and top-notch education, I not only honed my skills but also secured a coveted placement. Infividhya has been the key to unlocking my career.',
                    name: 'Dhruvil Dasadiya',
                    desigantion: 'GTPL',
                },
                {
                    id: 3,
                    img: 'assets/images/clients/Priyal.png',
                    text: 'This course is amazing because it bridges the gap between academics and industry experience. I love that all modules are well-structured, making it easy to learn and implement. From the first dashboard to the first internship, it was truly worth being with Infividhya.',
                    name: 'Priyal Bhavsar',
                    desigantion: 'Student',
                },
                {
                    id: 4,
                    img: 'assets/images/clients/kamal.png',
                    text: "InfiVidhya's course is a game changer, bridging the gap between academic learning and industry demands. They offer a clear path for learning and implementation. Transitioning from theory to hands-on experience is made easy, culminating in valuable internship opportunities.",
                    name: 'Kamal Govani',
                    desigantion: 'Bluwheelz',
                },
                {
                    id: 5,
                    img: 'assets/images/clients/Riya.png',
                    text: 'InfiVidhya seamlessly integrates academic and industry learning, offering rewarding experiences from dashboard to internship, setting a high standard for experiential education, and empowering students for real-world success. My journey with Infividhya has been very good.',
                    name: 'Riya Soni',
                    desigantion: 'Student',
                },
            ],
        };
        this.sliderRef = React.createRef();
    }

    componentDidMount() {
        this.setEqualHeight();
        window.addEventListener('resize', this.setEqualHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setEqualHeight);
    }

    setEqualHeight = () => {
        const items = document.querySelectorAll('.testimonial-item');
        let maxHeight = 0;

        // Reset height to auto to correctly calculate the new max height on resize
        items.forEach(item => {
            item.style.height = 'auto';
        });

        // Calculate the maximum height
        items.forEach(item => {
            if (item.offsetHeight > maxHeight) {
                maxHeight = item.offsetHeight;
            }
        });

        // Set all items to the maximum height
        items.forEach(item => {
            item.style.height = `${maxHeight}px`;
        });
    };

    render() {
        const sliderSettings = {
            ...HomeTwoTestimonial,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        return (
            <section className="testimonial section-padding">
                <div className="container-fluid container-grid">
                    <div className="testimonials-slides-3" ref={this.sliderRef}>
                        <Slider {...sliderSettings}>
                            {this.state.testimonial.map((data) => (
                                <div className="testimonial-item" key={data.id}>
                                    <div className="testimonial-inner">
                                        <div className="testimonial-text">
                                            <div className="quote-icon mb-2"><i className="flaticon-left-quote"></i></div>
                                            {data.text}
                                        </div>
                                        <div className="testimonial-author-container">
                                            <div className="client-img mb-4" style={{ height: "125px", width: "125px" }}>
                                                <img
                                                    src={data.img}
                                                    alt={data.name}
                                                    className="rounded-full"
                                                />
                                            </div>
                                            <div className="testimonial-author text-center">
                                                <h4 className="text-lg font-semibold">{data.name}</h4>
                                                <span className="text-sm text-gray-600">{data.desigantion}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Review;
