import React, { Component } from "react";
import ContactUsForm from "./ContactForm";

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [
        {
          id: 1,
          icon: "fa fa-envelope",
          title: "info@infividhya.com",
          type: "email",
        },
        {
          id: 2,
          icon: "fa fa-phone-alt",
          title: "+91 6351377405",
          type: "phone",
        },
        {
          id: 3,
          icon: "fa fa-phone-alt",
          title: "+91 9227037044",
          type: "phone",
        },
        {
          id: 4,
          icon: "fa fa-map-marker",
          title:
            "727, Gala Empire, Drive In Road Ahmedabad, Gujarat 380054, IN",
        },
      ],
    };
  }
  render() {
    return (
      <section className="contact section-padding">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-4 col-lg-5">
              <div className="contact-info-wrapper mb-5 mb-lg-0">
                <h3>
                  what's your story?
                  <span>Get in touch</span>
                </h3>
                <p>We would love to connect with you and help you.</p>

                {this.state.contacts.map((data, i) => (
                  <div className="contact-item" key={data.id}>
                    <i className={data.icon}></i>
                    {data.type ? (
                      <a
                        href={
                          (data.type === "email" ? "mailto:" : "tel:") +
                          data.title
                        }
                      >
                        {data.title}
                      </a>
                    ) : (
                      <h5>{data.title}</h5>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="col-xl-7 col-lg-6">
              <ContactUsForm formStyle="contact__form form-row " />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactInfo;
