import React, { Component } from 'react';


class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            process: [
                {
                    id: 1,
                    step: '01',
                    title: 'Live Learning',
                    text: 'Immerse yourself in dynamic, real-time learning experiences that bring education to life.',
                    extraName: 'bg-1',
                },
                {
                    id: 2,
                    step: '02',
                    title: 'Industry Relevant Content',
                    text: "Access cutting-edge curriculum curated to meet the demands of today's ever-evolving industries.",
                    extraName: 'bg-2',
                },
                {
                    id: 3,
                    step: '03',
                    title: 'Guest Lecturers',
                    text: ' Gain invaluable insights from industry experts and thought leaders through exclusive guest lectures. ',
                    extraName: 'bg-3',
                },
                {
                    id: 4,
                    step: '04',
                    title: 'Soft Skills Development',
                    text: 'Enhance your professional toolkit with tailored soft skills training designed to empower your personal and career growth.',
                    extraName: 'bg-1',
                }
            ]
        }
    }
    render() {
        return (
            <section className="work-process-section">
                <div className="container">
                    <div className="row mb-70 justify-content-between">
                        <div className="col-xl-5">
                            <div className="section-heading text-center text-lg-start mb-4 mb-xl-0">
                                <h2 className="font-lg">Services</h2>
                                <span className="subheading">Offered by Experts for Success</span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <p className="text-center text-lg-start">InfiVidhya provides a range of services to help you excel in the IT industry and achieve your career goals. Our courses are designed to be practical and hands-on, so you can gain real-world experience and build your skills.</p>
                        </div>
                    </div>

                    <div className="row">
                        {
                            this.state.process.map((data, i) => (
                                <div className="col-xl-3 col-lg-6 col-md-6" key={data.id}>
                                    <div className="step-item ">
                                        <div className={`step-number ${data.extraName}`}>{data.step}</div>
                                        <div className="step-text">
                                            <h5>{data.title}</h5>
                                            <p>{data.text}</p>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </section>



        );
    }
}

export default Process;
