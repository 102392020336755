import React from 'react';
import { Banner, Counter, CourseCat01, Process, Features, Review, Cta, MarqueeComponent } from '../layouts/home02/index';
import HeaderTwo from '../../common/header/Header';
import FooterTwo from '../../common/footer/Footer';
import Marquee from "react-fast-marquee";
import './HomeTwo.css'; // Import the CSS file

const HomeTwo = () => {
    return (
        <div className="home-page-2">
            <Marquee className="custom-marquee">
                Data Science and Full Stack Courses are currently running ! ! ! !   Limited Seats Available          ! ! ! !
            </Marquee>
            <HeaderTwo />
            <Banner />
            <Features />
            <CourseCat01 />
            <Counter />
            <Cta />
            <Process />
            <Review />
            <MarqueeComponent />
            <FooterTwo />
        </div>
    );
}

export default HomeTwo;
