import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 me-auto col-sm-8">
            <div className="footer-logo mb-3">
              <img
                src="assets/images/logo-white.png"
                alt="InfiVidhya Logo"
                className="img-fluid"
              />
            </div>
            <div className="widget footer-widget mb-5 mb-lg-0">
              <p>
                Learn, Upskill, Succeed
                <br />
                Elevate your career with our industry-relevant in-person
                upskilling Bootcamp
              </p>
            </div>
          </div>

          <div className="col-xl-2 col-sm-4">
            <div className="footer-widget mb-5 mb-xl-0">
              <h5 className="widget-title">Explore</h5>
              <ul className="list-unstyled footer-links">
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/course">Course</Link>
                </li>
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-2 col-sm-4">
            <div className="footer-widget mb-5 mb-xl-0">
              <h5 className="widget-title">Address</h5>
              <ul className="list-unstyled footer-links">
                <li>
                  <h6 className="text-white">Phone</h6>
                  <Link to="tel:+916351377405">+91 6351377405</Link>
                </li>
                <li>
                  <h6 className="text-white">Email</h6>
                  <Link to="mailto:info@infividhya.com">
                    info@infividhya.com
                  </Link>
                </li>
              </ul>
              <div className="footer-socials mt-4">
                <a href="https://www.instagram.com/infividhya/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/infividhya/">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-btm">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-sm-12 col-lg-6">
              <p className="mb-0 copyright text-sm-center text-lg-start">
                © {new Date().getFullYear()} All rights reserved by{" "}
                <Link to="#">InfiVidhya</Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed-btm-top">
        <a href="#top-header" className="scroll-to-top">
          <i className="fa fa-angle-up"></i>
        </a>
      </div>
    </section>
  );
};

export default FooterTwo;
