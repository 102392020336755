import React from 'react';
import Marquee from 'react-fast-marquee';
import './MarqueeComponent.css';

const logos = [
    { src: '/assets/images/companies/bluwheelz.png', name: 'BluWheelz' },
    { src: 'assets/images/companies/gtpl.png', name: 'GTPL' },
    { src: '/assets/images/companies/mirecle.png', name: 'Mirecle' },
    { src: '/assets/images/companies/petpooja.svg', name: 'PetPooja' },
    { src: '/assets/images/companies/pixiverse.jpg', name: 'Pixiverse' },
    { src: '/assets/images/companies/raio.jpg', name: 'Raio' },
    { src: '/assets/images/companies/amiha.svg', name: 'Amiha' },
    { src: '/assets/images/companies/version.jpg', name: 'Version' },
    { src: '/assets/images/companies/Solarium.png', name: 'Solarium' },
    { src: '/assets/images/companies/Uppsala.svg', name: 'Uppsala University' },
];

const MarqueeComponent = () => {
    return (
        <div>
            <h2 className="text-center">Our students outreach at</h2>
            <Marquee gradient={false} speed={50} pauseOnHover={true} className="marquee-container">
                {logos.map((logo) => (
                    <div key={logo.name} className="marquee-card">
                        <div className="marquee-image">
                            <img src={logo.src} alt={`Company logo ${logo.name}`} className="marquee-logo" />
                        </div>
                        <div className="marquee-info">
                            <hr className="line" />
                            <p className="company-name">{logo.name}</p>
                        </div>
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeComponent;
